import "./intro.scss";
import IntroImg from "../../img/intro.jpg";
import Logo from "../../img/logo.svg";

export default function Intro() {
  return (
    <div className="outro">
      <div className="intro-left">
        <div className="intro-left-wrapper">
          <a href="#contact">
            <h3 className="outro-getintouch">Get in touch.</h3>
            <h4 className="outro-sendemail">Send email to me</h4>
            <div className="intro-skills">
              <div className="intro-skills-wrapper">
                <div className="intro-skills-item">Python</div>
                <div className="intro-skills-item">Javascript</div>
                <div className="intro-skills-item">NodeJS</div>
                <div className="intro-skills-item">React</div>
                <div className="intro-skills-item">NextJS</div>
                <div className="intro-skills-item">Web Developer</div>
              </div>
            </div>
          </a>
        </div>
        <img src={Logo} alt="" className="intro-logo" />
      </div>
      <div className="intro-right">
        <div className="intro-bg"></div>
        <img src={IntroImg} alt="" className="intro-img" />
      </div>
    </div>
  );
}
