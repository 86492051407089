import "./about.scss";
import Programmer from "../../img/about.jpeg";
import AboutExp from "../../img/about.png";

const About = () => {
  return (
    <div className="about" id="about">
        <div className="a-left">
          <div className="a-card bg"></div>
          <div className="a-card">
            <img
              data-aos="zoom-out-right"
              src={Programmer}
              alt=""
              className="a-img"
            />
          </div>
      </div>
      <div className="a-right">
        <h3 data-aos="fade-left" data-aos-delay="300" className="a-title">
          About = (me) ={">"} &#123;
        </h3>
        <p data-aos="flip-left" data-aos-delay="350" className="a-sub">
          Programming - Software development - Web development
        </p>
        <p data-aos="flip-left" data-aos-delay="400" className="a-desc">
          My goal is to create innovative and impactful desktop and web solutions that solve
          real-world problems and make a difference. I value teamwork,
          creativity, and quality, and I strive to deliver high-standard results
          that meet the needs and expectations of clients and users.
        </p>
        <div className="a-exp">
          <img
            data-aos="zoom-out-down"
            data-aos-delay="500"
            src={AboutExp}
            alt=""
            className="a-exp-img"
          />
          <div className="a-exp-texts">
            <h4 className="a-exp-title">Experience & Projects</h4>
            <p className="a-exp-desc">
              As a software developer I build efficient and user-friendly Python applications and web
              applications using mainly JavaScript, NodeJS, React and NextJS. I
              collaborated with other developers, devops engineers, testers,
              designers, and clients to deliver high-quality products that meet
              their needs and expectations. I also contribute to code reviews,
              testing, and debugging to ensure optimal performance and
              functionality. I am passionate about coding and learning new
              technologies, and I always seek to improve and challenge myself.
            </p>
          </div>
        </div>
        <h2 className="a-title">&#125;</h2>
      </div>
    </div>
  );
};

export default About;
