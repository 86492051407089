import "./productList.scss";
import Product from "../product/Product";
import { products } from "../../data";

const ProductList = () => {
  return (
    <div className="pl" id="projects">
      <div className="pl-text">
      <h2
            className="pl-title"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="300"
            data-aos-anchor-placement="top-bottom"
          >
          {"<"}Demo Projects{">"}
        </h2>
        <p className="pl-desc">[Create & Inspire]</p>
      </div>
      <div className="pl-list">
        {products.map((item) => (
          <Product
            key={item.id}
            id={item.id}
            img={item.img}
            link={item.link}
            code={item.code}
            stack={item.stack}
            title={item.title}
            info1={item.info1}
            info2={item.info2}
            info3={item.info3}
            info4={item.info4}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
